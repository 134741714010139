.nickname {
    background: rgba(58, 64, 74, 0.651);
    padding: 5px !important;
    position: absolute;
    z-index: 999;
    color: #ffffff;
    right: 5px;
}
.form-control {
    color: black;
}

.pointer {
    cursor: pointer;
}

#closeButton {
    position: absolute;
    top: -3px;
    right: 0;
    z-index: 999;
}

#name-error-text {
    color: #fd6d5f;
    font-weight: bold;
    text-align: center;
}

#nicknameForm {
    padding: 10px;
    
}
.fullscreen {
    top: 40px;
}

.streamComponent {
    height: 100%;
}

video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    color: #ffffff;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

#statusIcons {
    bottom: 0;
    background: #c71100;
    width: 40px;
    height: fit-content;
    position: absolute;
    color: #ffffff;
}

#camIcon,
#micIcon {
    text-align: center;
    padding: 6px;
}

#fullscreenButton {
    position: absolute;
    bottom: 1px;
    right: 1px;
    z-index: 1000;
    background-color: #000000c4;
}
#volumeButton {
    background-color: #000000c4;
    position: absolute;
    bottom: 45px;
    right: 1px;
    z-index: 1000;
    color: #ffffff;
}
/* Contains the video element, used to fix video letter-boxing */
.OT_widget-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

#input,
#label {
    color: white;
}
#OT_video-poster {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: none;
  
    opacity: 0.25;
  
    background-repeat: no-repeat;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDcxIDQ2NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48bGluZWFyR3JhZGllbnQgaWQ9ImEiIHgyPSIwIiB5Mj0iMSI+PHN0b3Agb2Zmc2V0PSI2Ni42NiUiIHN0b3AtY29sb3I9IiNmZmYiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmYiIHN0b3Atb3BhY2l0eT0iMCIvPjwvbGluZWFyR3JhZGllbnQ+PHBhdGggZmlsbD0idXJsKCNhKSIgZD0iTTc5IDMwOGMxNC4yNS02LjUgNTQuMjUtMTkuNzUgNzEtMjkgOS0zLjI1IDI1LTIxIDI1LTIxczMuNzUtMTMgMy0yMmMtMS43NS02Ljc1LTE1LTQzLTE1LTQzLTIuNSAzLTQuNzQxIDMuMjU5LTcgMS0zLjI1LTcuNS0yMC41LTQ0LjUtMTYtNTcgMS4yNS03LjUgMTAtNiAxMC02LTExLjI1LTMzLjc1LTgtNjctOC02N3MuMDczLTcuMzQ2IDYtMTVjLTMuNDguNjM3LTkgNC05IDQgMi41NjMtMTEuNzI3IDE1LTIxIDE1LTIxIC4xNDgtLjMxMi0xLjMyMS0xLjQ1NC0xMCAxIDEuNS0yLjc4IDE2LjY3NS04LjY1NCAzMC0xMSAzLjc4Ny05LjM2MSAxMi43ODItMTcuMzk4IDIyLTIyLTIuMzY1IDMuMTMzLTMgNi0zIDZzMTUuNjQ3LTguMDg4IDQxLTZjLTE5Ljc1IDItMjQgNi0yNCA2czc0LjUtMTAuNzUgMTA0IDM3YzcuNSA5LjUgMjQuNzUgNTUuNzUgMTAgODkgMy43NS0xLjUgNC41LTQuNSA5IDEgLjI1IDE0Ljc1LTExLjUgNjMtMTkgNjItMi43NSAxLTQtMy00LTMtMTAuNzUgMjkuNS0xNCAzOC0xNCAzOC0yIDQuMjUtMy43NSAxOC41LTEgMjIgMS4yNSA0LjUgMjMgMjMgMjMgMjNsMTI3IDUzYzM3IDM1IDIzIDEzNSAyMyAxMzVMMCA0NjRzLTMtOTYuNzUgMTQtMTIwYzUuMjUtNi4yNSAyMS43NS0xOS43NSA2NS0zNnoiLz48L3N2Zz4=);
    background-size: auto 76%;
    
  }
  