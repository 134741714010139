#header {
    color: #ffffff;
    height: 40px;
    background-color: #0f3761;
    padding: 0 14px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999999;
    min-width: 400px !important;
  }
  .toolbar{
    padding: 0 !important;
  }
  
  .headerLight{
    color: #706969 !important;
    background-color: #eeeeee !important;
  }
  
  
  #navSessionInfo {
    position: absolute;
  }

.buttonsContent {
    color: #ffffff;
  }
  #navButtons {
    position: absolute;
    left: 0;
    right: 0;
    min-width: 400px;
    color:#ffffff
  }
  #navChatButton {
    position: absolute;
    right: 10px;
    top: 0;
    color:#ffffff
  }
  
  #titleContent{
    max-width: 500px;
    background-color: #ffffff8a;
    height: 20px;
    padding: 0px 5px;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    float: right;
    margin-top: 20px;
    color:#131414
  }
  
  .titleContentLight{
    background-color: #ffffff !important; 
    color: #000;
  }
  
  #header_img {
    max-width: 500px;
    width: 160px;
    height: 38px;
    margin-top: 9px;
    margin-bottom: 5px;
  }

 #session-title{
  position: relative;
  left: 50px;
  color:#ffffff;
  font-size: large;
  font-family: cursive, sans-serif;

  }


#point{
  width: 10px;
  height: 10px;
  position: absolute;
  top: 12px;
  right: 33px;
  border-radius: 50%;
  background-color: #ffa600;
  border: 1px solid #000;
  z-index: 99999;
}
.pointLight{
  border: 1px solid #ffffff !important;
}


@media only screen and (max-width: 700px) {
  #titleContent, #navChatButton {
      display: none;
  }
}
